/* eslint-disable class-methods-use-this */

import Service from './Service';

class PanelService extends Service {
  constructor() {
    super('panels');
  }

  getFieldsTablePanels() {
    return [
      {
        key: 'model',
        label: 'Modelo do Painel',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
      {
        key: 'power',
        label: 'Potência (KW)',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
      {
        key: 'dimensions',
        label: 'Área',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
      {
        key: 'price',
        label: 'Custo ',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
      {
        key: 'actions',
        label: 'Ações',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
    ];
  }
}

const panelService = new PanelService();
export default panelService;
