<template>
    <div>
      <b-row class="card">
        <div class="w-100">
          <h5 class="title-card">
            <i class="fas fa-pen"></i> Filtros
          </h5>
        </div>

        <div class="w-100 p-3">
          <b-row>
            <b-col lg="6">
              <b-form-group label="Modelo do Painel">
                <b-form-input v-model="filters.panel"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="2">
              <b-form-group label="Potência (KW)">
                <b-form-input v-model="filters.potency"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="4" class="align-btn">
              <b-button variant="info" class="mr-2">Pesquisar</b-button>
              <b-button variant="danger" @click="resetInput">Limpar</b-button>
            </b-col>
          </b-row>
        </div>
      </b-row>
      <b-row class="card mt-5">
        <div class="w-100">
          <h5 class="title-card">
            <i class="fas fa-solar-panel"></i> Painéis Cadastrados
          </h5>
        </div>
        <div class="w-100 p-3">
          <b-button variant="primary" :to="{ name: 'panel-create' }">
            <i class="fas fa-plus"></i> Adicionar novo painel
          </b-button>
        </div>
        <div class="w-100 p-3">
          <b-table
          id="tb-panels"
          responsive
          bordered
          :current-page="currentPage"
          :per-page="perPage"
          :busy="load"
          :items="panels"
          :fields="columsTablePanels">
            <template #table-busy>
              <div class="text-center text-success my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Carregando...</strong>
              </div>
            </template>
            <template v-slot:cell(actions)="data">
                <div>
                <b-button
                :to="{ name: 'panel-edit', params: { id: data.item.id } }"
                v-b-tooltip.hover
                title="Editar Painel"
                size="sm"
                class="mr-1"
                variant="warning">
                    <i class="fas fa-pencil-alt"></i> Editar
                </b-button>
                <b-button
                @click="confirmRemove(data.item.id)"
                title="Excluir painel"
                size="sm"
                class="mr-1"
                variant="danger"
                >
                  <i class="fas fa-trash"></i> Excluir
                </b-button>
                </div>
            </template>
          </b-table>
        </div>

        <div class="w-100 py-3" v-if="totalRows > 15">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
        ></b-pagination>
      </div>
      </b-row>
    </div>
  </template>

<script>
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import PanelService from '@/Services/PanelService';

export default {
  data() {
    return {
      id_remove: null,
      columsTablePanels: PanelService.getFieldsTablePanels(),
      load: true,
      totalRows: 0,
      currentPage: 1,
      perPage: 15,
      panels: [],
      filters: {
        potency: '',
        panel: '',
      },
      payload: {
        model: '',
        power: '',
        dimensions: '',
        price: '',
      },
    };
  },
  mixins: [MessagesMixin, QuestionMixin],
  methods: {
    async getAll() {
      // const filters = { ...this.filters };

      try {
        const { data } = await PanelService.getList();
        this.totalRows = data.length;
        this.panels = data.map((item) => ({
          id: item.id,
          model: item.model,
          power: item.power,
          dimensions: item.dimensions,
          price: this.$options.filters.currency(item.price),
        }));
      } catch (error) {
        console.log(error);
      }
      this.load = false;
    },
    confirmRemove(id) {
      this.id_remove = id;
      this.$toast.question(
        'Deseja realmente remover esse transformador ?',
        'Atenção',
        this.getQuestionObj(this.deletePanel, '', 'Inversor removido'),
      );
    },
    async deletePanel() {
      try {
        await PanelService.delete(this.id_remove);
        this.getAll();
      } catch (error) {
        console.log(error);
      }
    },
    resetInput() {
      this.filters.potency = '';
      this.filters.panel = '';
    },
  },
  created() {
    this.getAll();
  },
};
</script>
